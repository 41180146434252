import axios from '@/config/axios'

// 保养工单-待执行列表接口
export const getTodoMaintainList = (params) => axios({
    method: 'get',
    url: `/mobile/deviceMaintainOrder/my/todo`,
    params
})

// 保养工单-已处理列表接口
export const getFinishMaintainList = (params) => axios({
    method: 'get',
    url: `/mobile/deviceMaintainOrder/my/finish`,
    params
})

// 保养工单-接单保养工单
// export const receiveMaintainOrder = (data) => axios({
//   method: 'put',
//   url: `/mobile/deviceMaintainOrder/receive`,
//   data
// })

// 保养工单-保管工单详细信息
// export const maintainOrderDetail = (id) => axios({
//   method: 'get',
//   url: `/mobile/deviceMaintainOrder/detail/${id}`,
// })


// 完成保养工单
// export const finishOrder = (data) => axios({
//   method: 'put',
//   url: `/mobile/deviceMaintainOrder/finish`,
//   data
// })












// 进入点检任务线路前确认接口
export const getPreCheckStatus = (taskId) => axios({
    method: 'get',
    url: `/mobile/checkTask/preCheck/${taskId}`
})

// 进入点检任务的点检线路接口
export const getEnterCheckTask = (taskId) => axios({
    method: 'get',
    url: `/mobile/checkTask/enterCheckTask/${taskId}`
})

// 退出点检任务的点检线路接口
export const getCancelCheck = (taskId) => axios({
    method: 'get',
    url: `/mobile/checkTask/cancelCheck/${taskId}`
})

// 进入点检任务点检线点检设备任务详细页接口
export const getEnterCheckLineDevice = (taskId, deviceId) => axios({
    method: 'get',
    url: `/mobile/checkTask/enterCheckLineDevice/${taskId}/${deviceId}`
})

// 提交点检任务中点检线路中单个设备的点检完成接口
export const postFinishDeviceCheck = (taskId, deviceId, checkOrder, standardId, totalTimes, params) => axios({
    method: 'post',
    url: `/mobile/checkTask/finishDeviceCheck/${taskId}/${deviceId}/${standardId}/${checkOrder}/${totalTimes}`,
    data: params
})


// 完成点检任务的点检提交接口
export const postFinishCheckTask = (taskId) => axios({
    method: 'post',
    url: `/mobile/checkTask/finishCheckTask/${taskId}`
})






/**
 * 
 * @param {*保养接口，二次修改} type 
 */

// 设备类型设备树接口
export const getDeviceTypeTree = () => axios({
    method: 'get',
    url: `/deviceTypeEnum/getDeviceTypeTree`,
})

// 完成点检任务的点检提交接口
export const deviceDict = (type) => axios({
    method: 'get',
    url: `/deviceDict/${type}`,
})

// 设备类型
export const deviceTypeEnumList = (type) => axios({
    method: 'get',
    url: `/deviceTypeEnum/list`,
})





// 保养工单-待执行列表接口
export const getMaintainList = (params) => axios({
    method: 'get',
    url: `/mobile/deviceRegularMaintenanceOrder/h5/maintain/list`,
    params
})

// 保养工单-接单保养工单
export const receiveMaintainOrder = (orderId) => axios({
    method: 'get',
    url: `/mobile/deviceRegularMaintenanceOrder/receive/${orderId}`,

})

// 保养工单-保管工单详细信息
export const maintainOrderDetail = (orderId) => axios({
    method: 'get',
    url: `/mobile/deviceRegularMaintenanceOrder/order/detail/${orderId}`,
})

// 完成保养工单
export const finishOrder = (orderId, params) => axios({
    method: 'GET',
    url: `/mobile/deviceRegularMaintenanceOrder/finish/${orderId}`,
    params
})

// 重新提交工单
export const resubmitOrder = (orderId, params) => axios({
    method: 'GET',
    url: `/mobile/deviceRegularMaintenanceOrder/resubmit/${orderId}`,
    params
})

// 补单（已过期的工单进行补单操作）
export const supplementOrder = (orderId, params) => axios({
    method: 'GET',
    url: `/mobile/deviceRegularMaintenanceOrder/supplement/${orderId}`,
    params
})



// 审批定期保养工单接口
export const approvalOrder = (orderId, status) => axios({
    method: 'GET',
    url: `/mobile/deviceRegularMaintenanceOrder/approval/${orderId}/${status}`,
})


